import { useEffect, useState } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import "./NewBigProductSlider.css";
import image1 from "../../../Components/Images/big-product-slider/1.jpg";
import image2 from "../../../Components/Images/big-product-slider/2.jpg";
import { useNavigate } from "react-router";
import { BannerService } from "../../../Services/BannerService";
import { Subscription } from "rxjs";
import {
  ApiCallResponse,
  PageEventTypeEnum,
} from "../../../Model/ApiCallResponse";
import { ApiCallState } from "../../../Model/Enums/ApiCallState";
import { BannerStore, apiLoadingSubject } from "../../../Store";
import { messageConstants } from "../../../StringConstants";
import { PositionEnum } from "../../../Model/Enums/PositionEnum";
import Carousel from "react-material-ui-carousel";
import { SearchParamsService } from "../../../Services/SearchParamsService";
import { showErrorToast } from "../../../utils/Toastify/ToastifyHandler";

const NewBigProductSlider = () => {
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const bannerData = new BannerService();

  const getBanners = () => {
    bannerData.getBanner();
  };

  useEffect(() => {
    getBanners();
    let bannerSubscriber: Subscription = apiLoadingSubject.subscribe(
      (response: ApiCallResponse) => {
        if (PageEventTypeEnum.BANNER === response.eventType) {
          switch (response.apiCallState) {
            case ApiCallState.LOADING:
              setIsBannerLoading(true);
              break;
            case ApiCallState.LOADING_SUCCESS:
              setIsBannerLoading(false);
              break;
            case ApiCallState.LOADING_ERROR:
              showErrorToast(messageConstants.REFRESH);
              setIsBannerLoading(false);
              break;
          }
        }
      }
    );
    return () => {
      if (bannerSubscriber) {
        bannerSubscriber.unsubscribe();
      }
    };
  }, []);

  const productSlider = [
    {
      imgURL: image1,
      desc: "Provides the quality that you expected",
      btn: "Shop",
      position: PositionEnum.BRAND_1,
    },

    {
      imgURL: image2,
      desc: "Weekend Discount",
      btn: "Shop",
      position: PositionEnum.BRAND_2,
    },
  ];

  useEffect(() => {
    const swiper = new Swiper(".bigProduct_slider", {
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      speed: 2000,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        500: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    });

    return () => {
      swiper.destroy(true, true);
    };
  }, []);

  const navigateToProduct = (id: number) => {
    navigate(`/product?`);
    SearchParamsService.setParams([
      { key: "brandIds", value: id.toString() },
      { key: "isDefaultCatalogue", value: "true" },
      { key: "sCid", value: "" },
      { key: "cid", value: "" },
      { key: "selectedScid", value: "" },
      { key: "selectedCid", value: "" },
      { key: "productName", value: "" },
      { key: "category", value: "" },
    ]);
    window.scrollTo(0, 0);
  };

  return (
    <div className="bigProduct_slider swiper paddingTop paddingBottom">
      <div className="swiper-wrapper">
        {productSlider.map((slide, index) => (
          <div key={index} className="swiper-slide">
            <Carousel
                autoPlay={true}
                swipe={true}
                //   navButtonsAlwaysVisible={false}
                animation={"fade"}
                interval={3000}
                duration={1000}
                indicators={false}
            >
              {BannerStore.getBannerPositionMap(slide.position).map((item) => (
                  <div className="bigProduct_item" key={item.id}>
                    <div className="bigProduct_content">
                      {/* <h4 className="fw-bold black_clr mb-3"></h4> */}
                      <button
                        className="theme_btn"
                        //@ts-ignore
                        onClick={() => navigateToProduct(item.brand?.id)}
                      >
                        Shop Now
                      </button>
                    </div>
                    <img
                      className="img-fluid w-100"
                      src={item.bannerUrl}
                      alt="Product-img"
                    />
                  </div>
              ))}
            </Carousel>
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default NewBigProductSlider;

