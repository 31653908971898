import React, {useEffect} from 'react';
import './App.css';
import NewRoutes from './routes/NewRoutes';
import 'react-toastify/dist/ReactToastify.css';
import MountToastContainer from './utils/Toastify/MountToastContainer';
import './utils/Toastify/Toastify.css'
import {requestNotificationPermission} from "./Firebase/Messaging";

const App = () => {
  // useEffect(() => {
  //   requestNotificationPermission()
  // },[])
  return (
    <>
    <div className="App" >
      <MountToastContainer />
      <NewRoutes />
      <div id='alert'></div>
    </div>
    </>
  );
}

export default App;
